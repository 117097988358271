.divFoto {
    position: relative;
    display: inline-block;
    text-align: center;
  }
  
  .divLetras {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   
  }