.sobreMi {
    background-image: url('../images/fondoSobreMi.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    justify-content: center;
    margin: 0 !important;
    width: 100%;
}


.nombreDoctor {
     text-shadow: 0 0 0.2em rgb(239, 60, 33), 0 0 0.2em rgb(239, 60, 33);   

} 

.listaCargos {
 
    /* text-shadow: 0 0 0.2em #F87, 0 0 0.2em #F87;  */
}
.estudios {
    color: #070A52;
}


/* 
.imagenDoctor {
    max-width: 400px;
   
} */

.tituloEstudios {
    /* color: rgb(16, 5, 75);
    text-shadow: 0 0 0.2em #F87, 0 0 0.2em #F87;    */
    /* text-shadow: 0 0 0.2em rgb(244, 238, 238), 0 0 0.2em rgb(250, 210, 204);    */
    text-shadow: 0 0 0.2em rgb(239, 60, 33), 0 0 0.2em rgb(239, 60, 33); 
    padding-top: 100px;

}

.divider {
   
        height: 4px;
        width: 30%;
        background-color: #b64869;
       
    
    }

    @media screen and (max-width:500px){
        .subtituloEstudios {
            font-size: 15px;
            font-weight: bold;
        }
    
      
     }
    
     @media screen and (max-width:370px){
        .subtituloEstudios {
            font-size: 12px;
            font-weight: bold;
        }
    
      
     }