.containerMain{
    background-image: url('../images/fondoInicio2.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    justify-content: center;
    margin: 0 !important;
    width: 100%;
}

.parrafoInicio {
  /* padding-top: 30px; */
  /* color:rgb(40, 107, 151); */
  /* text-shadow: 0 0 0.2em rgb(35, 157, 176),0 0 0.2em rgb(35, 157, 176); */
  text-shadow: 0 0 0.2em rgb(143, 158, 218),0 0 0.2em rgb(167, 181, 237);
}

.tituloNombre {
    color: rgb(89, 160, 204);
    text-shadow: 0 0 0.2em rgb(240, 241, 245),0 0 0.2em rgb(240, 241, 245);
    /* color:dimgray;
     text-shadow: 0 0 0.2em rgb(22, 24, 33),0 0 0.2em rgb(22, 24, 33); */
}
.tituloPatologias {
    color:darkblue;
    text-shadow: 0 0 0.2em rgb(35, 157, 176),0 0 0.2em rgb(35, 157, 176);
    /* color: rgb(89, 160, 204);
    text-shadow: 0 0 0.2em rgb(240, 241, 245),0 0 0.2em rgb(240, 241, 245); */
    /* text-shadow: 0 0 0.2em rgb(240, 241, 245),0 0 0.2em rgb(240, 241, 245); */
    /* color:deepskyblue; */
}

.listaPatologia {
    text-shadow: 0 0 0.2em #F87, 0 0 0.2em #F87;  
}
.botonLeerMas {
    background-color: gray;
    max-width: 15%;
    
}

.botonLeerMas:hover {
    background-color: rgb(89, 160, 204);
}

.imagenHigado {
    min-height: 600px;
    background-image: url('../images/fotoHigadoGuante.jpg');
    background-size: cover;
    background-position: center;
    margin-top: 30px;
    
}


@media screen and (max-width:990px){
   .descripcionMedico {
       margin-left: 100px;
   }
}

@media screen and (max-width:500px){
    .descripcionMedico {
        margin-left: 20px;
    }

    .botonLeerMas {
        max-width: 30%;
    }
 }

  